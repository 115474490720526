
.com-article-component {
    position: relative;
    .subject-year-wrap {
        position: absolute;
        right: -7px;
        top: 10px;
        display: flex;
    }
    .com-title {
        border-bottom: none;
    }
    ul.com-article {
        margin-top: 7px; //17
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        background: url(../assets/img/border1.png);
        background-size: 1px 50%;
        background-repeat: repeat-y;
        background-position: 50% 0%;
        li {
            width: 530px;
            border-bottom: 1px solid #e6e6e6;
            h5 {
                
                margin-top: 20px;
                line-height: 20px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                width: 530px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                img {
                    width: 16px;
                    height: 20px;
                    margin-right: 7px;
                }
                span {
                    width: 507px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 18px;
                }
            }
            .cursor-h5 {
                cursor: pointer;
            }
            div {
                display: flex;
                margin-bottom: 19px;
                p {
                    color: #666666;
                    font-size: 16px;
                    line-height: 17px;
                    margin-right: 26px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                p.author {
                    width: 174px;
                }
                p.name {
                    width: 180px;
                }

                p.time {
                }
            }
        }
        li:nth-child(2n + 1) {
            margin-right: 34px;
        }
        li:nth-child(2n + 2) {
            margin-left: 33px;
            width: 536px;
            h5 {
                width: 536px;
            }
        }
        li:nth-last-child(2) {
            margin-bottom: 0;
        }
        li:last-child {
            margin-bottom: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
