
.com-book-component {
    position: relative;
    .com-title {
        border-bottom: none;
    }
    .subject-year-wrap {
        position: absolute;
        right: -7px;
        top: 10px;
        display: flex;
    }
    ul.com-book {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 25px;
            margin-bottom: 31px;
            img {
                width: 207px;
                height: 277px;
                margin-bottom: 16px;
                cursor: pointer;
                box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
            }
            p {
                width: 207px;
                line-height: 16px;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        li:nth-child(5n + 5) {
            margin-right: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
